type MdorTypes = {
  document_custom_id?: string
  token?: string
  file?: string
  project?: string
  error?: string
}

type GetMdorPropTypes = {
  companyCode: string
  contractNo: string
  brand: string
  recaptchaToken: string
}

const getMdor = async ({
  companyCode,
  contractNo,
  brand,
  recaptchaToken,
}: GetMdorPropTypes): Promise<MdorTypes> => {
  const query = `mutation MdorEmployeeLogin(
    $employeeMdorLoginInput: EmployeeMdorLoginInput!
  ) {
    mdorEmployeeLogin(
      employeeMdorLoginInput: $employeeMdorLoginInput
    ) {
      token
      document {
          mdor_document {
              document_custom_id
              file
              project: assigned_project
          }
      }      
    }
  }`

  try {
    const response = await fetch(process.env.GATSBY_API_URL || '', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        query,
        variables: {
          employeeMdorLoginInput: {
            companyCode,
            contractNo,
            brand,
            recaptchaToken,
          },
        },
      }),
    })

    const jsonResponse = await response.json()
    const mdorEmployeeLoginResponse = jsonResponse.data.mdorEmployeeLogin

    if (mdorEmployeeLoginResponse.document.mdor_document === null) {
      return {
        error: 'No uploaded document yet',
      }
    }

    const {
      token,
      document: { mdor_document: { document_custom_id = '', file = '', project = '' } }
    } = mdorEmployeeLoginResponse

    return {
      token,
      document_custom_id,
      file,
      project,
    }
  } catch (e) {
    return {
      error: 'Invalid credential. Please contact Customer Service',
    }
  }
}

export default getMdor


