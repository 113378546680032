import React, { FormEvent, memo, ReactElement } from 'react'

type InputPropTypes = {
  id: string
  name: string
  type?: 'text' | 'email' | 'password'
  placeholder: string
  error?: string
  half?: boolean
  onChange?(event: FormEvent<HTMLInputElement>): void
  onBlur?(event: FormEvent<HTMLInputElement>): void
}

const Input = ({
  id,
  name,
  type = 'text',
  placeholder,
  error,
  half,
  onChange,
  onBlur,
}: InputPropTypes): ReactElement => {
  return (
    <div className={`form-field-container${half ? ' half' : ''}`}>
      <div className={`form-field${error ? ' has-error' : ''}`}>
        <input
          type={type}
          id={id}
          name={name}
          placeholder={placeholder}
          onChange={onChange}
          onBlur={onBlur}
        />
        <label htmlFor={id}>{placeholder}</label>
      </div>
      <p className="form-field-error">{error}</p>
    </div>
  )
}

export default memo(Input)
