import React, { ReactElement, useCallback, useState } from 'react'
import './MDOR.scss'
import ReCAPTCHA from 'react-google-recaptcha'
import Viewer from './Viewer/Viewer'
import getMdor from '../../services/getMdor'
import Form, {
  FormInput,
  FormContent,
  useFormState,
} from '../../components/Form/Form'
import MDORFormValidation from './MDOR.validation'
import logo from '../../assets/images/mdor-logo.png'
import Seo from '../../components/Seo/Seo'

let captcha: any

const MDOR = (): ReactElement => {
  const {
    isFormDisplayed,
    values,
    errors,
    onFieldValueChange,
    onFieldBlur,
    validateFields,
    resetFields,
  } = useFormState({
    validation: MDORFormValidation,
  })

  const [mdorFile, setMdorFile] = useState<string>('')
  const [mdorFileToken, setMdorFileToken] = useState<string>('')
  const [error, setError] = useState<string>('')
  const [isAgreeChecked, setIsAgreeChecked] = useState<boolean>(false)
  const [isRecaptchaChecked, setIsRecaptchaChecked] = useState<boolean>(false)
  const [
    isShowErrorTermsAndCondition,
    setIsShowErrorTermsAndCondition,
  ] = useState<boolean>(false)
  const [isShowErrorRecaptcha, setIsShowErrorRecaptcha] = useState<boolean>(
    false,
  )
  const [recaptcha, setRecaptcha] = useState<string | null>('')

  const openChatSupport = useCallback(() => {
    if (window.SnapEngage) {
      window.SnapEngage.openProactiveChat(true, true)
    }
  }, [])

  const handleSubmit = useCallback(async () => {
    if (!isAgreeChecked) {
      setIsShowErrorTermsAndCondition(true)
    }

    if (!isRecaptchaChecked) {
      setIsShowErrorRecaptcha(true)
    }

    const fieldErrors = validateFields(values)
    if (!Object.keys(fieldErrors).length) {
      const { companyCode, contractNo } = values
      const { file, token, error: requestError } = await getMdor({
        companyCode,
        contractNo,
        brand: 'AMAIA',
        recaptchaToken: recaptcha || '',
      })

      if (file) {
        setMdorFile(file)
        setMdorFileToken(token as string)
      } else {
        setError(
          requestError || 'Invalid credential. Please contact Customer Service',
        )
      }

      // captcha.reset()
      setIsRecaptchaChecked(false)
      setIsAgreeChecked(false)
      resetFields()
    }
  }, [
    isAgreeChecked,
    isRecaptchaChecked,
    recaptcha,
    resetFields,
    validateFields,
    values,
  ])

  if (mdorFile) {
    return <Viewer pdfFile={mdorFile} mdorFileToken={mdorFileToken} />
  }

  return (
    <>
      <Seo title="Amaia MDDOR" />
      <div className="mdor">
        <div className="mdor-content">
          <div className="mdor-content-form">
            <img src={logo} alt="logo" />
            <h3 className="title">MDOR Portal</h3>
            <span className="mdor-content-form-error">{error}</span>
            <Form onSubmit={handleSubmit} display={isFormDisplayed}>
              <FormInput
                id="companyCode"
                name="companyCode"
                placeholder="Enter Company Code"
                onChange={onFieldValueChange}
                onBlur={onFieldBlur}
                error={errors.companyCode}
              />
              <FormInput
                id="contractNo"
                name="contractNo"
                placeholder="Enter Contract Number"
                onChange={onFieldValueChange}
                onBlur={onFieldBlur}
                error={errors.contractNo}
              />
              <div className="i-agree-container">
                <label htmlFor="i-agree">
                  <input
                    id="i-agree"
                    type="checkbox"
                    onChange={(): void => {
                      setIsAgreeChecked(!isAgreeChecked)
                    }}
                  />
                  {/* eslint-disable-next-line jsx-a11y/control-has-associated-label, jsx-a11y/role-has-required-aria-props */}
                  <span role="checkbox" />
                </label>
                We use cookies to improve your browsing experience. By
                continuing to browse this site, you understood and agree to our{' '}
                <a href="/privacy-policy" target="_blank">
                  Privacy Notice
                </a>
                {isShowErrorTermsAndCondition && !isAgreeChecked && (
                  <p>You must agree to the Terms and Conditions.</p>
                )}
              </div>
              <div className="recaptcha-container">
                <ReCAPTCHA
                  sitekey={process.env.GATSBY_RECAPTCHA_API_KEY || ''}
                  onChange={(value: string | null): void => {
                    setRecaptcha(value)
                    setIsRecaptchaChecked(!isRecaptchaChecked)
                  }}
                  /* eslint-disable-next-line @typescript-eslint/explicit-function-return-type, no-return-assign */
                  ref={(e) => (captcha = e)}
                />
                {isShowErrorRecaptcha && !isRecaptchaChecked && (
                  <p>Please verify that you are not a robot.</p>
                )}
              </div>
              <FormContent>
                <button
                  type="submit"
                  className="login-button"
                  // onClick={handleSubmit}
                >
                  Login
                </button>
                <p className="service">
                  Can’t log in?
                  <button
                    onClick={openChatSupport}
                    type="button"
                    className="link"
                  >
                    Contact the Cutomer Service
                  </button>
                </p>
              </FormContent>
            </Form>
          </div>
          <div className="mdor-content-footer">
            <p>© 2020 Amaia Land</p>
            <p>DESIGNED BY WHITE CLOAK TECHNOLOGIES</p>
          </div>
        </div>
      </div>
    </>
  )
}

export default MDOR
