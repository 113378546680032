export const hasValue = (value: string): boolean =>
  value ? !!value.trim() : !!value

export const isEmail = (value: string): boolean => {
  const re = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g
  return re.test(String(value).toLowerCase())
}

export const isPhone = (value: string): boolean => {
  const re = /^[0-9-]+$/g
  return re.test(String(value).toLowerCase())
}

export const isPhoneWithPrefix = (value: string): boolean => {
  const re = /^[0-9-+]+ [0-9-]+$/g
  return re.test(String(value).toLowerCase())
}

export default {}
